export default {
    methods: {
        formatFigure: function (item) {
            return item ? new Intl.NumberFormat('en-US' , {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(item) : '0.00'
        },
        retrieveMonth(monthNumber) {
			switch (monthNumber) {
				case 1:
					return "January";
				case 2:
					return "February";
				case 3:
					return "March";
				case 4:
					return "April";
				case 5:
					return "May";
				case 6:
					return "June";
				case 7:
					return "July";
				case 8:
					return "August";
				case 9:
					return "September";
				case 10:
					return "October";
				case 11:
					return "November";
				case 12:
					return "December";
				default:
					return "Unspecified Month";
			}
		},
    }
}