<template>
	<div>
		<!-- filter payslip -->
		<v-form @submit.prevent="findEmployeePayroll" v-model="valid" ref="payslipForm" lazy-validation>
			<v-row>
				<v-col class="d-flex" cols="12" md="3">
					<v-autocomplete :items="employeeIdsNames" item-text="displayName" item-value="employeeId"
						label="Select Employee..." v-model="paySlipForm.employeeId"
						:rules="[selectionRequired('Employee')]" dense solo flat background-color="white lighten-4"
						class="text--center"></v-autocomplete>
				</v-col>

				<v-col cols="12" md="4">
					<v-menu ref="startMenu" v-model="startMenu" :close-on-content-click="false"
						:return-value.sync="paySlipForm.startYearMonth" transition="scale-transition" offset-y
						max-width="290px" min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field solo flat dense v-model="paySlipForm.startYearMonth"
								:rules="[required('Start Month')]" label="Start Date" prepend-inner-icon="mdi-calendar"
								readonly v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="paySlipForm.startYearMonth" type="month" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="startMenu = false">
								Cancel
							</v-btn>
							<v-btn text color="primary" @click="
			$refs.startMenu.save(paySlipForm.startYearMonth)
			">
								OK
							</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>

				<v-col cols="12" md="4  ">
					<v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false"
						:return-value.sync="paySlipForm.endYearMonth" transition="scale-transition" offset-y
						max-width="290px" min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field :rules="[required('End Date')]" solo flat dense
								v-model="paySlipForm.endYearMonth" label="End Date" prepend-inner-icon="mdi-calendar"
								readonly v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="paySlipForm.endYearMonth" type="month" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="endMenu = false">
								Cancel
							</v-btn>
							<v-btn text color="primary" @click="$refs.endMenu.save(paySlipForm.endYearMonth)">
								OK
							</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>

				<v-col cols="12" md="1">
					<v-btn @click="findEmployeePayroll" color="primary">find</v-btn>
				</v-col>
			</v-row>
		</v-form>
		<div v-if="this.individualPayroll.length === 0">
			<v-row>
				<v-col cols="12" md="12" class="text-center">
					Empty data or No employee selected
				</v-col>
			</v-row>
		</div>
		<div v-else-if="this.individualPayroll.length">
			<pay-slip-thumbnail :employees="individualPayroll" :totalPages="totalPages"></pay-slip-thumbnail>
		</div>
		<div>
			<spinner v-if="isLoading"></spinner>
		</div>
	</div>
</template>

<script>
import * as roles from "../../services/roles";
import payslipMixin from "../../services/mixins/payslipMixin"

export default {
	mixins: [payslipMixin],
	data: () => ({
		actionType: "fetchNspIndividualPayslip",
		sourcePath: "report",
	}),
	computed: {
		individualPayroll() {
			const individualData = this.$store.getters["report/nspIndividualPayroll"];

			return roles?.isConfidential() ? individualData : individualData?.filter((item) => {
				return item?.employee?.isManager === 0
			});

		},
		employeeIdsNames() {
			const employees = this.$store.getters["employees/employeeIdsNames"];
			const nspEmployees = employees.filter(
				(employee) => employee.employeePosition === "NSP"
			);
			return nspEmployees;
		},
	},
};
</script>
