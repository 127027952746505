import formatMixin from "./formatFigureMonthMixin";
import moment from "moment";
export default {
  mixins: [formatMixin],
  methods: {
    currentMonth() {
      return ("0" + (new Date().getMonth() + 1)).slice(-2);
    },
    getDateMonth() {
      const year = this.currentYear();
      const month = this.currentMonth();
      return { yearMonth: `${year}-${month}` };
    },
    getDate(date) {
      const month = moment(date, "YYYY-M").format("MMMM");
      const year = moment(date, "YYYY-M").format("YYYY");
      return `${month}  ${year}`;
    },
    async handleFilter() {
      this.isLoading = true;
      const data = {
        yearMonth: `${this.date}`,
      };
      let param = {};
      switch (this.$data.actionType) {
        case "fetchCurrentNspPayroll":
          param = {
            page: this.page || 1,
            yearMonth: data.yearMonth,
            searchName: "",
          };
          break;
        case "fetchPayrollReport":
          param = { page: this.page, data: this.filterForm.date };
          break;
        default:
          param = {
            page: this.page,
            data: data,
          };
      }
      await this.$store.dispatch(`${this.$data.sourcePath}/${this.$data.actionType}`, 
        param
      );
      this.isLoading = false;
    },
  },
};
