<template>
  <div>
    <div>
      <div>
        <v-container grid-list-md text-xs-center>
          <v-layout row wrap>
            <v-flex md3 sm12 lg3 v-for="item in employees" :key="item.employeeId">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card class="mx-auto thumbnail" max-width="20vw">
                    <p class="primary py-2 mb-n1" style="text-align: center">
                      <span class="white--text">{{
              item?.employee?.displayName
            }}</span>
                    </p>

                    <div style="text-align: center" class="payslip-month">
                      <span>
                        Payslip for
                        <strong>{{
                retrieveMonth(item?.month) + ", " + item?.year
              }}</strong>
                      </span>
                    </div>

                    <div>
                      <v-row class="px-0">
                        <v-col cols="6" sm="6" md="6" class="pl-5">
                          <div class="d-flex justify-space-between payslip-style">
                            <label><span>Staff Name: </span></label>
                            <div>
                              <p>{{ item?.employee?.displayName }}</p>
                            </div>
                          </div>
                          <div class="d-flex justify-space-between payslip-style">
                            <label><span>Department: </span></label>
                            <div className="d-flex ">
                              <p>
                                {{ setDepartment(item) }}
                              </p>
                            </div>
                          </div>
                          <div class="d-flex justify-space-between payslip-style">
                            <label><span>Email: </span></label>
                            <div className="d-flex ">
                              <p>
                                {{ item?.employee?.workEmail }}
                              </p>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6" sm="6" md="6" class="pr-5">
                          <div class="d-flex justify-space-between payslip-style">
                            <label><span>Staff ID</span></label>
                            <div>
                              <p>
                                {{ payrollId(item) }}
                              </p>
                            </div>
                          </div>

                          <div class="d-flex justify-space-between payslip-style">
                            <label><span>Job Description</span></label>
                            <div>
                              <p>
                                {{ item?.employee?.employeePosition }}
                              </p>
                            </div>
                          </div>

                          <div v-if="item.__typename !== 'NspPayroll'"
                            class="d-flex justify-space-between payslip-style">
                            <label><span>Basic Salary</span></label>
                            <div>
                              <p>
                                {{ formatFigure(item?.employee?.basicSalary) }}
                              </p>
                            </div>
                          </div>
                          <div v-if="item.__typename === 'NspPayroll'"
                            class="d-flex justify-space-between payslip-style">
                            <label><span>Monthly Allowance</span></label>
                            <div>
                              <p>
                                {{
              formatFigure(item?.monthlyAllowancePayable)
            }}
                              </p>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="px-2 mt-1">
                        <v-col cols="12" sm="12" md="12">
                          <label class="payslip-earnings"><strong>Earnings</strong></label>
                          <table v-if="item.grossSalary" aria-label="">
                            <th scope="col"></th>
                            <tbody class="payslip-style">
                              <tr>
                                <td class="text-left py-2 px-5">
                                  Gross Salary
                                </td>
                                <td class="text-right py-2 px-5">
                                  {{ formatFigure(item?.grossSalary) }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left py-2 px-5">Net Salary</td>
                                <td class="text-right py-2 px-5">
                                  {{ formatFigure(item?.netSalary) }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table v-else aria-label="">
                            <th scope="col"></th>
                            <tbody class="payslip-style">
                              <tr>
                                <td class="text-left py-2 px-5">
                                  Total Earnings
                                </td>
                                <td class="text-right py-2 px-5">
                                  {{
              formatFigure(item?.employee?.basicSalary)
            }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </div>
                    <v-fade-transition>
                      <v-overlay v-if="hover" absolute color="#036358" z-index="10000">
                        <v-btn color="primary" @click.prevent="showDialog(item)">
                          Preview
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-card>
                </template>
              </v-hover>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle></v-pagination>
    </div>
    <dialog-pay-slip :closeDialog="closeDialog" :dialog="dialog" :showPreview="false" :data="item"></dialog-pay-slip>
  </div>
</template>

<script>
import DialogPaySlip from "../../views/reports/DialogPaySlip";
import filterMixin from "../../services/mixins/filterCurrentMonthMixin";
import dialogMixin from "../../services/mixins/dialogMixin";
import validation from "../../services/validation";

export default {
  name: "PaySlipThumbnail",
  mixins: [filterMixin, dialogMixin],
  components: { DialogPaySlip },
  props: {
    employees: {
      type: Array,
      default: null,
    },
    totalPages: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    ...validation,
    overlay: false,
    dialog: false,
    isLoading: false,
    item: {},
    year: null,
    month: null,
    date: "",
    sourcePath: "report",
    actionType: "fetchBankAdviceReport",
    menu: false,
  }),

  computed: {
    bankAdviceData() {
      return this.$store.state.report.bankAdviceReport.content;
    },
    data() {
      let today = new Date();
      let date =
        today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2);
      return {
        yearMonth: date,
      };
    },
    payslipTotalPages() {
      return this.$store.getters["report/totalPages"];
    },
    page: {
      get() {
        return this.$store.getters["report/currentPage"];
      },
      set(val) {
        return this.$store.commit("report/SET_CURRENT_PAGE", val);
      },
    },
  },

  methods: {
    setDepartment(item) {
      let tmp = item?.employee?.employeeId.slice(0, 3);
      let scTmp = item?.employee?.employeeId.slice(0, 2);
      let newItem = { ...item.employee };

      if (scTmp === "SC") {
        newItem.department = "SERVICE CENTER";
      } else if (tmp === "TRN") {
        newItem.department = "TRAINING CENTER";
      } else if (tmp === "NSP") {
        newItem.department = "NATIONAL SERVICE";
      } else {
        newItem.department = "OPERATIONS";
      }
      return newItem.department;
    },
    closeDialog() {
      this.dialog = false;
    },
    showDialog(data) {
      this.item = data;
      this.dialog = true;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    async initialize() {
      await this.$store.dispatch("report/fetchBankAdviceReport", {
        page: 1,
        data: this.data,
      });
    },
    async paginatePage() {
      this.isLoading = true;
      this.scrollToTop();
      await this.$store.dispatch("report/fetchBankAdviceReport", {
        page: this.page,
        data: this.data,
      });
      this.isLoading = false;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.thumbnail {
  height: 38vh;
  overflow: hidden;
}

.payslip-month {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  padding: 0.6rem;
}

.payslip-earnings {
  font-family: "Times New Roman", Times, serif;
  font-size: 12px;
}

.payslip-style {
  font-family: "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: lighter;
  color: black;
}

.payslip-date {
  border-bottom: 3px solid #cf4f1f;
  font-weight: bold;
  text-align: center;
}

.table-border {
  border-bottom: 3px solid #cf4f1f;
  border-top: 3px solid #cf4f1f;
  font-size: 1.2rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border: 2px solid #dadada;
}

.col-3 {
  padding: 10 !important;
  min-width: 100%;
}
</style>
