export default {
    methods: {
        payrollId(item) {
            let tmp = item?.employee?.employeeId.slice(0, 3);
            let scTmp = item?.employee?.employeeId.slice(0, 2);
            let newItem = { ...item.employee };
            let nspItem = { ...item };
            let id = "";
            const pattern = /_\d+_\d{4}$/;
            if (scTmp === "SC") {
              id = newItem?.employeeId;
            } else if (tmp === "TRN") {
              id = newItem?.employeeId;
            } else if (tmp === "NSP") {
              id = nspItem?.payrollId.replace(pattern, "");
            } else {
              id = newItem?.employeeId;
            }
            return id;
          },
    },
}